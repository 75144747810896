#main-nav {
    text-transform: uppercase;
    margin: 0px auto;
    padding: 0px;
    width: auto;
    height: auto;
    display: inline-block;
    opacity: 1;
    position: absolute;
    bottom: 0px;
    right: 15px;
    word-spacing: 0 !important;
    line-height: 0 !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.main-nav-item {
    position: relative;
}

.main-nav-dropdown_title {
    font-family: "Roboto Condensed", sans-serif, helvetica;
    font-weight: bold;
    display: inline-block;
    font-size: 1.6rem;
    line-height: 3em;
    cursor: pointer;
    color: #000;
    margin: 0px 20px 0px 20px;
    /* border-bottom: 5px solid #fff; */
    text-decoration: none;
}

.main-nav-dropdown_title:hover::after {
    /* border-bottom: 6px solid; */
    position: absolute;
    width: calc(100% - 30px);
    content: '';
    height: 5px;
    left: 15px;
    bottom: 0px;
    background: #000;
    margin: auto;
}

.main-nav-dropdown_title.list {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16' viewBox='0 0 10 16'%3E%3Cpath fill-rule='evenodd' d='M5 11L0 6l1.5-1.5L5 8.25 8.5 4.5 10 6l-5 5z'/%3E%3C/svg%3E");
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 18px;
    background-size: 14px 20px;
    cursor: pointer
}

.nav-dropdown-global {
    position: fixed;
    opacity: 0;
    height: 0;
    width: 100%;
    left: 0;
    transition: ease-in-out 0.3s;
    display: flex;
    z-index: -2;
    justify-content: center;
    color: #fff;
    font-size: 1.8rem;
    font-weight: bold;
}

.nav-dropdown-global.active {
    opacity: 1;
    transition: ease-in-out 0.3s;
    position: fixed;
    top: 115px;
    left: 0;
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    color: #fff;
    z-index: 2;
    background: rgba(0, 0, 0, .8);
    padding: 30px 0;
}

.nav-dropdown-global .splitter {
    border: 1px dashed #fff;
    height: 100%;
    margin: auto 30px;
}

.nav-dropdown-global.active li.drop-list,
.nav-dropdown-global.active li.drop-list a {
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 10px 0;
    cursor: pointer;
}

.nav-dropdown-global.active li:hover,
.nav-dropdown-global.active li.drop-list a:hover {
    color: #a2b2c8;
    text-decoration: none
}

.nav-dropdown-global .product-list {
    display: none;
}

.nav-dropdown-global.active .product-list {
    display: inline-block;
}

.nav-dropdown-global.active ul.header {
    padding: 0;
    margin: 15px 0;
    line-height: 2rem;
}

#mobileNavHamburgerButton {
    display: none;
    height: 29px;
    width: 29px;
    cursor: pointer;
    background-image: url("/src/assets/images/masthead__hamburger.svg");
    margin: 57px 20px 0 0;
    background-repeat: no-repeat;
    background-position: 0 0;
    z-index: 1011;
    color: #fff;
    position: fixed;
    right: 0px;
    top: 0px
}

.mobileNavMenu {
    /* display: block; */
    font-family: "Roboto Condensed", sans-serif, helvetica;
    font-size: 1.8rem;
    position: fixed;
    top: 0px;
    right: -100%;
    height: 100%;
    width: 60%;
    z-index: 9998;
    overflow: hidden;
    text-align: right;
    margin: 0 auto;
    transition: .5s ease-in-out;
}

.mobileNav__bg.active {
    opacity: 1;
    /* right: 100%; */
    background: rgba(255, 255, 255, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 9998;
}

.mobileNavMenu.active {
    right: 0;
    transition: .5s ease-in-out;
}

.logoCloseMobile {
    content: "";
    position: absolute;
    z-index: 4;
    top: 0;
    right: 0;
    padding: 0;
    text-align: left;
    height: auto;
    background-color: #000;
    width: calc(90% + 50px)
}

.logoCloseMobile__x {
    height: 16px;
    width: 16px;
    position: relative;
    padding: 17px;
    cursor: pointer;
    display: block;
    text-align: left;
}

.mainNav__mobile {
    position: relative;
    width: 90%;
    margin: auto;
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.mainNav__mobile:first-child {
    margin-top: 13px
}

.mainNavMobile {
    position: absolute;
    top: 0;
    right: 0;
    width: 90%;
    z-index: 2;
    height: calc(100% - 50px);
    background: rgba(0, 0, 0, .8);
    overflow-y: scroll;
    padding-top: 50px;
    text-align: left
}

.mainNavMobile__title {
    color: #fff;
    font-size: 1.8rem;
    font-weight: bold;
    display: block;
    cursor: pointer;
    position: relative;
    text-transform: uppercase;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.mainNavMobile__title a {
    color: #fff;
    font-size: 1.8rem;
    text-decoration: none;
}

.mainNav__mobile .list-icon {
    height: 20px;
    width: 20px;
    background-color: rgba(88, 88, 88, 0);
    background-image: url("/src/assets/images/arrow_mobile.svg");
    background-position: center center;
    background-size: cover;
    text-decoration: none;
    position: absolute;
    transition: 200ms;
    right: 0px;
    top: 16px;
    overflow: visible
}

.mainNav__mobile .list-icon.active {
    transform: rotate(90deg);
}

.mainNavMobile__title:hover,
.mainNavMobile__title a:hover {
    color: #b1c4c9;
    text-decoration: none
}

.mobile-nav-droplist {
    height: 0;
    opacity: 0;
    transition: ease-in-out .4s;
}

.mobile-nav-droplist.active {
    color: #fff;
    background-color: rgba(0, 0, 0, .3);
    display: block;
    position: relative;
    width: 100%;
    height: 230px;
    opacity: 1;
    overflow: hidden;
    transition: ease-in-out .4s;
}

.mobile-nav-droplist .product-list {
    display: none;
    /* height: 0;
    opacity: 0; */
}

.mobile-nav-droplist.active .product-list {
    display: block;
}

.mobile-nav-droplist .product-list .header {
    background-color: rgba(0, 0, 0, 0.3);
    font-size: 1.5rem;
    text-decoration: none;
    font-family: Roboto, sans-serif, helvetica;
    font-weight: 500;
    display: block;
    padding: 10px 30px;
    cursor: default;
    margin-bottom: 10px;
}

.mobile-nav-droplist .product-list .drop-list {
    color: rgb(255, 255, 255);
    display: block;
    padding: 6px 0px 6px 30px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.4rem;
    margin-right: 20px;
    line-height: 1.6rem;
}

.mobile-nav-droplist .product-list .drop-list a {
    color: rgb(255, 255, 255);
    text-decoration: none;
}

.mobile-nav-droplist .product-list .drop-list,
.mobile-nav-droplist .product-list .drop-list a:hover {
    color: #a2b2c8;
    text-decoration: none
}

@media(max-width: 992px) {
    #main-nav {
        display: none;
    }

    #mobileNavHamburgerButton {
        display: block
    }
}

@media(max-width: 500px) {
    .mobile-nav-droplist.active {
        height: auto;
    }
}

@media(max-height: 880px) {
    .mobileNavMenu {
        overflow: auto
    }

    .mobileNavMenu {
        width: 90%;
    }
}


.asofdate {
    color: #000;
    font-weight: normal;
    font-size: 1.3rem;
    font-style: italic;
    letter-spacing: 0
}

.bold {
    font-weight: bold
}

.no-bold {
    font-weight: normal
}

.callout {
    color: #0a85c7;
    font-family: "Roboto", sans-serif, helvetica
}

#tab2 .disclosure,
#tab1 .disclosure {
    margin-bottom: 0px
}

.banner-blue-background {
    background-color: #0a85c7;
    border-top: 0px solid #fff;
    height: auto
}

.home-icon-svg {
    background-image: url();
    background-size: 34px 33.66666667px;
    background-position: 0 17px;
    background-repeat: no-repeat;
    width: 33.66666667px;
    height: 60px;
    display: block
}

@media(max-width: 992px) {

    #tab2 .disclosure,
    #tab1 .disclosure {
        padding-bottom: 0px
    }
}

.italic {
    font-style: italic
}

.knockout {
    color: #fff
}

.lowercase {
    text-transform: lowercase
}

.uppercase {
    text-transform: uppercase
}

.indent {
    margin-left: 50px;
    padding-right: 50px
}

.first {
    padding-top: 0;
    margin-top: 0
}

.last {
    padding-bottom: 0;
    margin-bottom: 0
}

.no-margin {
    margin: 0
}

.no-padding {
    padding: 0
}

.no-width,
width-none {
    width: 0px
}

.clear {
    clear: both
}

.clear-left {
    clear: left
}

.clear-right {
    clear: right
}

.float-right {
    float: right
}

.float-left {
    float: left
}

.full-width,
.u-full-width {
    width: 100%;
    box-sizing: border-box
}

.max-full-width,
.u-max-full-width {
    max-width: 100%;
    box-sizing: border-box
}

.responsive {
    max-width: 100%;
    height: auto
}

.margin-top-20 {
    margin-top: 20px
}

.margin-top-30 {
    margin-top: 30px
}

.margin-left-20 {
    margin-left: 20px
}

.margin-top-50 {
    margin-top: 50px
}

.margin-bottom-20 {
    margin-bottom: 20px
}

.margin-bottom-30 {
    margin-bottom: 30px
}

.margin-bottom-20 {
    margin-bottom: 20px
}

.margin-bottom-50 {
    margin-bottom: 50px
}

.padding-top-10 {
    padding-top: 10px
}

.padding-top-50 {
    padding-top: 50px
}

.padding-right-35 {
    padding-right: 35px
}

.padding-left-10 {
    padding-left: 10px
}

.padding-left-70 {
    padding-left: 70px
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

section,
.section {
    position: relative;
    padding: 0 20px;
    margin-bottom: 20px;
    min-height: 150px
}

section--inverted,
.section--inverted {
    background-color: #0a85c7;
    border: 1px solid #156c98;
    margin-bottom: 20px;
    position: relative;
    min-height: 150px;
    padding: 20px
}

.section__content,
.section__content--right,
.section__content--center {
    padding: 15px
}

.section__content--center {
    text-align: center
}

.section__content--right {
    text-align: right
}

.section__portfolio-manager-thumbnail {
    margin-top: 22px
}

.section__portfolio-manager-row {
    margin-bottom: 30px
}

.section__portfolio-manager-row h5 {
    margin-top: 18px
}

.nobio p {
    color: red
}

.section__heading--center {
    text-align: center
}

.section__heading--left {
    text-align: left;
    padding: 15px
}

.section__heading--right {
    text-align: right;
    padding: 15px
}

.section__heading--mod-1 {
    color: #0a85c7;
    font-weight: bold;
    font-size: 3rem;
    display: block;
    padding: 15px
}

.section__heading--mod-2 {
    color: #0a85c7;
    text-transform: uppercase;
    padding: 15px
}

.section__heading--mod-3 {
    border-bottom: 1px #0a85c7 solid;
    padding: 15px
}

.section__heading--mod-4 {
    background: #0a85c7;
    color: #fff;
    padding: 15px;
    display: block;
    font-size: 2.4rem;
    font-weight: bold
}

.section__type-big {
    color: #000;
    font-size: 2.4rem;
    font-weight: bold;
    padding: 15px;
    float: left
}

.section__type-big--inverted {
    color: #fff;
    font-size: 2.4rem;
    font-weight: bold;
    padding: 15px
}

.section__bg-1,
.section__bg-3,
.section__bg-2 {
    position: relative;
    z-index: 3;
    width: 100vw;
    background: #f5f5f5;
    background-repeat: no-repeat;
    background-position: right center
}

.section__bg-1 h3.underline,
.section__bg-3 h3.underline,
.section__bg-2 h3.underline {
    border-bottom: 2px solid #ddd
}

.section__bg-1 .container,
.section__bg-3 .container,
.section__bg-2 .container {
    overflow: visible
}

.section__bg-2 {
    position: relative;
    background-image: none;
    background-position: initial
}

.section__bg-2 img.section__img-subtle {
    position: absolute;
    z-index: 2;
    top: -200px;
    pointer-events: none;
    float: left
}

.section__bg-3 {
    position: relative;
    background-image: none;
    background-position: initial;
    padding: 10px 0 80px 0
}

.section__bg-3 img.section__img-subtle {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    pointer-events: none;
    float: right
}

.section__distribution {
    padding-bottom: 60px
}

p.bio_p {
    margin-left: 160px;
    position: relative
}

p.bio_p img {
    height: 179px;
    width: 139px;
    min-width: 139px;
    border: 0px solid red;
    position: absolute;
    top: 5px;
    left: -160px
}

@media(max-width: 992px) {
    p.bio_p {
        margin-left: 0px;
        margin-top: 200px
    }

    p.bio_p img {
        top: -200px;
        left: 0px
    }
}

.advisor_icons img {
    display: block;
    margin-bottom: 20px
}

.advisor_icons i {
    line-height: 2rem;
    text-transform: uppercase
}

.advisor_icons ul {
    margin-top: 16px;
    margin-left: 20px;
    margin-bottom: 20px
}

.advisor_icons ul li {
    padding-left: 0px;
    list-style: disc;
    list-style-position: outside
}

.quote {
    padding: 20px;
    border-top: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
    font-size: 1.4em;
    line-height: 2.8rem;
    color: #0a85c7
}

p.legend {
    padding-left: 26px;
    position: relative;
    margin-bottom: 8px
}

p.legend::before {
    border: 0px solid red;
    content: "";
    height: 17px;
    width: 17px;
    position: absolute;
    top: 3px;
    left: 0
}

p.legend.a::before {
    background: #0a85c7
}

p.legend.b::before {
    background: #a7a9ac
}

p.legend.c::before {
    background: #d7102c
}

.tableOverflowIndicatorMobile {
    /* color: #000; */
    width: 100%;
    text-align: center;
    margin: 10px 0 30px 0;
    font-family: Arial, sans-serif;
    display: none;
    color: rgb(164, 173, 177);
    animation: change 2s step-end both;
}

@media(max-width: 1000px) {
    .tableOverflowIndicatorMobile {
        display: block;
    }
}

@keyframes change {
    0% {
        color: orange
    }

    30% {
        opacity: 0;
        transition: 0.3s ease-in-out;
    }

    40% {
        opacity: 0.4;
        color: rgb(164, 173, 177);
        transition: 0.3s ease-in-out;
    }

    50% {
        color: rgb(164, 173, 177);
        opacity: 1;
    }
}

.tableHorizontalScroll {
    overflow: hidden;
    overflow-y: hidden;
    width: 100%
}

.tableHorizontalScroll table,
.tableHorizontalScroll tbody {
    overflow: hidden
}

.table,
.table--mod-1 {
    max-width: 1220px;
    width: 100%;
    margin: 10px 0 15px 0;
    line-height: 1.2em;
    box-sizing: border-box;
    z-index: 4;
    position: relative
}

.table th,
.table--mod-1 th {
    text-align: left;
    padding: 15px;
    color: #000;
    border-top: 1px solid #d6dadc;
    font-size: 1.4rem
}

.table th:first-child,
.table--mod-1 th:first-child {
    text-align: left
}

.table td,
.table--mod-1 td {
    padding: 5px 15px;
    vertical-align: middle;
    color: #000;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
    border-top: 1px solid #f5f5f5
}

.table td date,
.table--mod-1 td date {
    font-size: 1.4rem;
    margin: 0
}

.table td:first-child,
.table--mod-1 td:first-child {
    text-align: left
}

.table sup,
.table--mod-1 sup {
    color: #000;
    font-size: .9rem
}

.table--mod-1 {
    margin: 0;
    width: 100%
}

.table--mod-1 td {
    border: 0;
    padding: 18px 12px 16px 12px;
    text-align: left;
    border-bottom: 2px solid #ddd
}

.table--mod-1 td:first-child {
    width: 100%
}

.table--mod-1 tr:first-child th {
    border-top: 0px solid #ddd;
    border-bottom: 2px solid #ddd
}

.table--mod-1 img {
    height: 22px;
    width: 22px
}

.table--mod-1.table_alt1 tr:first-child th {
    border-top: 0px solid #ddd;
    white-space: nowrap
}

.table--mod-1.table_alt1 tr td,
.table--mod-1.table_alt1 tr th {
    padding: 18px 10px 16px 10px;
    text-align: center
}

.table--mod-1.table_alt1 date span,
.table--mod-1.table_alt1 date {
    font-weight: normal
}

.table--mod-1.table_alt1 img {
    height: 22px;
    width: 22px
}

.table--mod-1.table_alt1 tr th:first-child {
    text-align: left
}

.table--mod-1.table_alt1 tr td:first-child {
    text-align: left;
    white-space: pre
}

.table--mod-1.table_alt2 tr:first-child th {
    border-top: 0px solid #ddd;
    white-space: nowrap
}

.table--mod-1.table_alt2 tr td,
.table--mod-1.table_alt2 tr th {
    padding: 18px 12px 16px 12px;
    text-align: center
}

.table--mod-1.table_alt2 date span,
.table--mod-1.table_alt2 date {
    font-weight: normal
}

.table--mod-1.table_alt2 img {
    height: 22px;
    width: 22px
}

.table--mod-1.table_alt2 tr th:first-child {
    text-align: left
}

.table--mod-1.table_alt2 tr td:first-child {
    text-align: left;
    width: 50%
}

.table--mod-1.table_alt2 tr td:nth-child(2) {
    width: 40%
}

.subnav__wrapper {
    position: absolute;
    bottom: 0;
    min-height: 44px;
    width: 100%;
    background: #0a85c7;
    z-index: 400
}

.subnav__wrapper .container {
    overflow: visible
}

.subnav__wrapper.sticky {
    position: fixed;
    top: 114px;
    width: 100%;
    height: 44px;
}

.subnav {
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif, helvetica;
    font-weight: bold;
    display: flex;
    opacity: 1;
    height: auto;
    overflow: hidden;
    align-items: stretch;
    height: 44px;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.subnav_mobile-dropdown {
    display: none;
}

.subnav__item {
    font-weight: bold;
    line-height: 44px;
    padding: 0;
    margin: 0;
    background-image: none;
    position: relative
}

.subnav__item:after {
    content: "";
    background: rgba(0, 0, 0, 0);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 3px;
    width: 80%
}

.subnav.active.masthead-up {
    top: 14px;
    box-sizing: border-box
}

.subnav__item.active .subnav__link {
    color: #000
}

.subnav__item.active::after {
    content: "";
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0px;
    height: 100%;
    width: 100%;
    z-index: -1
}

.subnav__link {
    padding: 10px 16px;
    font-size: 1.4rem;
    color: #fff;
    cursor: pointer
}

.subnav__link:hover {
    text-decoration: none
}

.subnav-mobile {
    display: none
}

.subnav-mobile.masthead-up.active {
    position: fixed;
    top: 10px;
    width: 100%;
    z-index: 999;
    box-sizing: border-box
}

.subnav-mobile.active {
    position: fixed;
    top: 130px;
    width: 100%;
    z-index: 999;
    box-shadow: 0px -15px 20px 25px #fff;
    box-sizing: border-box
}

@media(max-width: 992px) {
    .subnav-column {
        display: none
    }
}

@media(max-width: 778px) {
    .subnav {
        opacity: 0;
        pointer-events: none;
        height: 0
    }

    .subnav_mobile-dropdown {
        display: block;
    }

    .subnav-mobile {
        display: block
    }
}

.news-record {
    margin-bottom: 30px
}

.news__headline {
    margin: 5px 0 0 0;
    font-size: 1.6rem
}

.hero-short,
.hero,
.hero-short__img-7,
.hero-short__img-6,
.hero-short__img-5,
.hero-short__img-4,
.hero-short__img-3,
.hero-short__img-2,
.hero-short__img-1 {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: height 250ms linear;
    background: #f5f5f5;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;
    position: relative;
    opacity: 1;
    overflow: visible;
    height: 177px;
    margin-top: 113px;
    width: 100%;
    z-index: 140
}

.hero-short .col,
.hero .col,
.hero-short__img-7 .col,
.hero-short__img-6 .col,
.hero-short__img-5 .col,
.hero-short__img-4 .col,
.hero-short__img-3 .col,
.hero-short__img-2 .col,
.hero-short__img-1 .col {
    margin: 0
}

.hero-short .row,
.hero .row,
.hero-short__img-7 .row,
.hero-short__img-6 .row,
.hero-short__img-5 .row,
.hero-short__img-4 .row,
.hero-short__img-3 .row,
.hero-short__img-2 .row,
.hero-short__img-1 .row {
    overflow: hidden
}

.hero-short.callout-resize,
.callout-resize.hero,
.callout-resize.hero-short__img-7,
.callout-resize.hero-short__img-6,
.callout-resize.hero-short__img-5,
.callout-resize.hero-short__img-4,
.callout-resize.hero-short__img-3,
.callout-resize.hero-short__img-2,
.callout-resize.hero-short__img-1 {
    height: 238px
}

.hero-short__title {
    font-size: 3rem;
    font-family: "Roboto", sans-serif, helvetica;
    font-style: italic;
    font-weight: 300;
    padding-top: 0;
    display: inline-block;
    margin-right: 15px;
    margin-top: 50px;
    letter-spacing: -1px;
    text-transform: none;
    color: #000;
    transition: all 250ms linear
}

.hero-short__ticker {
    padding-left: 15px;
    border-left: 1px solid #000;
    color: #000;
    font-size: 1.8em;
    font-weight: 300;
    display: inline-block;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif, helvetica;
    font-weight: bold;
    margin-top: 48px;
    margin-bottom: 0px;
    width: auto;
    text-align: left;
    transition: all 250ms linear
}

.hero-short__ticker a {
    font-size: 2rem;
    text-transform: none;
    margin-left: 20px;
    font-family: "Roboto", sans-serif, helvetica
}

.hero-short__ticker--static {
    text-transform: none;
    transition: all 250ms linear;
    font-family: "Roboto", sans-serif, helvetica;
    font-style: italic;
    font-weight: 300;
    font-size: 3em;
    display: block;
    opacity: 1;
    line-height: 1em;
    margin-top: 45px
}

.hero-short__ticker--static .mod1 {
    margin-top: 76px
}

.hero-short__ticker--static.mod1 {
    margin-top: 66px
}

.hero-short--collapsed {
    height: 111px !important
}

.hero-short--collapsed .hero-short__title {
    font-size: 2.2rem;
    margin: 24px 15px 0 0
}

.hero-short--collapsed .hero-short__ticker {
    font-size: 2.2rem;
    margin-top: 24px
}

.hero-short--collapsed .hero-short__ticker--static {
    border-left: 0;
    font-size: 2.6em;
    margin-top: 16px
}

.hero-short--collapsed .hero-short__ticker--static.mod1 {
    margin-top: 36px
}

.hero-short--collapsed.callout-resize {
    height: 172px !important
}

@media(max-width: 992px) {
    .hero-short__title {
        font-size: 2.2rem;
        margin-top: 54px
    }

    .hero-short__ticker {
        font-size: 2.2rem;
        margin-top: 54px
    }

    .hero-short--collapsed .hero-short__ticker--static {
        margin-top: 16px;
        font-size: 2.6em
    }
}

@media(max-width: 778px) {
    .hero-short__title {
        margin-top: 40px;
        display: block
    }

    .hero-short__ticker {
        margin-top: 0px;
        display: block;
        border-left: 0px solid #000;
        padding-left: 0px
    }

    .hero-short__ticker--static {
        margin-top: 50px;
        font-size: 2.6em
    }

    .hero-short--collapsed .hero-short__title {
        font-size: 2.2rem;
        margin-top: 10px;
        display: block
    }

    .hero-short--collapsed .hero-short__ticker {
        font-size: 2.2rem;
        margin-top: 0px;
        display: block;
        border-left: 0px solid #000;
        padding-left: 0px
    }

    .hero-short--collapsed .hero-short__ticker--static {
        margin-top: 22px;
        font-size: 2em
    }

    .hero-short--collapsed.callout-resize {
        height: 190px !important
    }
}

.callout {
    border-top: 1px #fff solid;
    box-sizing: border-box;
    padding: 20px 10px;
    min-height: 60px;
    width: 100%;
    background: #0a85c7;
    vertical-align: middle
}

.callout a {
    font-size: 1.8rem;
    color: #fff;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif, helvetica;
    font-weight: bold
}

@media(max-width: 576px) {
    .hero-short__ticker {
        font-size: 1.2em;
        line-height: 1.6rem;
        font-family: "Roboto", sans-serif, helvetica;
        font-weight: 500;
        font-style: italic
    }

    .hero-short__title {
        font-size: 1.4em;
        line-height: 2.6rem
    }

    .hero-short--collapsed .hero-short__ticker {
        font-size: .9em
    }

    .hero-short--collapsed .hero-short__ticker a {
        font-size: 1.4rem
    }

    .hero-short--collapsed .hero-short__ticker--static {
        font-size: 2em
    }

    .hero-short--collapsed .hero-short__title {
        font-size: 1.4em
    }

    .callout {
        padding: 10px 10px;
        min-height: 30px
    }

    .callout a {
        font-size: 1.2rem
    }
}

@media(max-width: 398px) {
    .callout {
        padding: 5px 10px;
        min-height: 30px
    }

    .callout a {
        font-size: .8rem
    }
}

.hero-short__img-1 {
    height: 350px;
    background-image: url("/src/assets/images/hero-home.jpg");
    position: fixed;
    top: 0;
    left: 0
}

.hero-short__img-1 .container {
    position: relative;
    height: 350px
}

.hero-short__img-2 {
    background-image: url("/src/assets/images/hero-fund-1.jpg");
    background-position: center center;
    position: relative;
    top: 0;
    left: 0
}

.callout-resize.hero-short__img-2.video-thumbnail,
.hero-short__img-3.video-element {
    height: 365px;
}

.callout-resize.hero-short__img-2.video-thumbnail .video-thumbnail-fund .slider-video-container,
.hero-short__img-3.video-element .video-thumbnail-fund .slider-video-container {
    right: 150px;
}

.video-thumbnail .slider-video-container,
.video-element .slider-video-container {
    margin-top: 10px;
}

@media(max-width: 1152px) {
    .callout-resize.hero-short__img-2.video-thumbnail .video-thumbnail-fund .slider-video-container,
    .hero-short__img-3.video-element .video-thumbnail-fund .slider-video-container {
        right: 10px;
    }
}
@media(max-width: 992px) {
    .callout-resize.hero-short__img-2.video-thumbnail,
    .hero-short__img-3.video-element {
        height: 450px;
    }
    .callout-resize.hero-short__img-2.video-thumbnail .video-thumbnail-fund,
    .hero-short__img-3.video-element .video-thumbnail-fund {
        display: flex;
        justify-content: center;
    }
    .callout-resize.hero-short__img-2.video-thumbnail .video-thumbnail-fund .slider-video-container,
        .hero-short__img-3.video-element .video-thumbnail-fund .slider-video-container {
        right: unset;
        bottom: 50px;
    }
  }

.hero-short__img-3 {
    background-image: url("/src/assets/images/hero-7.jpg");
    background-position: center center;
    position: relative;
    top: 0;
    left: 0
}

.hero-short__img-4 {
    background-image: url("/src/assets/images/hero-6.jpg");
    background-position: center center;
    position: fixed;
    top: 0;
    left: 0
}

.hero-short__img-5 {
    background-image: url("/src/assets/images/hero-5.jpg");
    background-position: center center;
    position: fixed;
    top: 0;
    left: 0
}

.hero-short__img-6 {
    background-image: url("/src/assets/images/hero-4.jpg");
    background-position: center center;
    position: fixed;
    top: 0;
    left: 0
}

.hero-short__img-7 {
    background-image: url("/src/assets/images/hero-7.jpg");
    background-position: center center;
    position: fixed;
    top: 0;
    left: 0
}

.hero {
    height: 350px
}

.hero__title {
    padding-left: 15px;
    border-left: 1px solid #000;
    color: #000;
    font-size: 3rem;
    font-weight: 300;
    display: inline-block;
    margin-top: 112px;
    margin-bottom: 0px;
    text-transform: none;
    transition: all 250ms linear
}

.hero__title--static {
    color: #000;
    font-size: 4rem;
    font-weight: 300;
    display: block;
    margin-top: 0px;
    margin-bottom: 0px;
    text-transform: none
}

.hero__heading {
    font-size: 7.9rem;
    padding: 0px;
    text-transform: uppercase;
    position: relative;
    font-weight: normal;
    margin: 0;
    line-height: 1em;
    color: #000;
    display: inline-block;
    z-index: 1
}

.hero__heading--static {
    font-family: "Roboto", sans-serif, helvetica;
    font-style: italic;
    font-weight: 300;
    font-size: 7.9rem;
    font-weight: normal;
    text-transform: uppercase;
    display: block;
    opacity: 1;
    line-height: 1em;
    margin: 0;
    transition: all 250ms linear
}

.hero__ticker {
    font-size: 2.2rem;
    font-family: "Roboto", sans-serif, helvetica;
    font-weight: bold;
    float: left;
    padding-top: 0;
    margin-right: 15px;
    margin-top: 55px;
    color: #0a85c7;
    transition: all 250ms linear
}

.hero__ticker--lg {
    font-size: 15rem;
    margin-top: 85px;
    display: block;
    color: #0a85c7;
    transition: all 250ms linear
}

.hero sup {
    font-size: 2rem;
    vertical-align: super;
    color: #000;
    z-index: 1
}

.fund-docs__regulatory li a,
.fund-docs__forms li a {
    color: #000;
    font-size: 2rem
}

.fund-docs__icon,
.fund-docs__icon--fund-data {
    background: url("/src/assets/images/fund-docs__icon.svg") no-repeat left center;
    padding: 5px 5px 5px 30px;
    line-height: 1.3em;
    display: inline-block;
    background-size: 20px 20px;
    color: #000;
    font-size: 2rem
}

.fund-docs__icon:hover,
.fund-docs__icon--fund-data:hover {
    background: url("/src/assets/images/fund-docs__icon--hover.svg") no-repeat left center;
    padding: 5px 5px 5px 30px;
    background-size: 20px 20px
}

.fund-docs__icon--fund-data {
    background-image: url("/src/assets/images/arrow-right.svg");
    background-size: 16px 16px;
    vertical-align: top
}

.fund-docs__icon--fund-data:hover {
    background-image: url("/src/assets/images/arrow-right.svg");
    background-size: 16px 16px;
    vertical-align: top
}

.dropdown {
    font-family: "Roboto", sans-serif, helvetica;
    font-size: 1.5rem;
    line-height: 1.2em;
    background-color: #fff;
    background-position: 92% 55%;
    background-repeat: no-repeat;
    background-image: url("/src/assets/images/dropdown__arrow.svg");
    background-size: 16px 16px;
    color: #000;
    border: 1px solid #000;
    position: relative;
    float: none;
    width: 440px;
    height: auto;
    padding: 10px 10px 10px 10px;
    margin: 0px 0px 0px 0px;
    cursor: pointer
}

.dropdown option {
    color: #727477;
    height: auto;
    font-family: "Roboto", sans-serif, helvetica;
    font-size: 1.5rem;
    line-height: 1em;
    padding: 6px 40px 6px 10px;
    background-color: #fff;
    text-shadow: 0 0 0 #727477;
    width: auto
}

.dropdown option:focus {
    color: #d6dadc;
    display: list-item
}

.dropdown optgroup {
    color: #000;
    height: auto;
    font-family: "Roboto", sans-serif, helvetica;
    font-size: 1.5rem;
    line-height: 1em;
    padding: 6px;
    background-color: #fff;
    outline: 0
}

.dropdown__container {
    display: inline-block;
    color: #000;
    font-weight: normal;
    line-height: 1em;
    padding: 10px
}

.dropdown--mobile {
    display: none
}

.styledSelect,
.styledSelectMobile {
    white-space: nowrap;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    min-width: auto;
    text-transform: uppercase;
    overflow: visible;
    padding: 4px 8px 3px 8px;
    margin: 6px 0;
    border: 2px solid #ddd;
    line-height: 1.5em;
    font-size: 1.5rem;
    font-family: "Roboto Condensed", sans-serif, helvetica;
    font-weight: bold;
    background-color: #fff;
    background-position: 97% 55%;
    background-repeat: no-repeat;
    background-image: url("/src/assets/images/dropdown__arrow.svg");
    background-size: 16px 16px;
    color: #000;
    height: auto;
    cursor: pointer;
    display: none
}

.s-hidden {
    visibility: hidden;
    display: none
}

.select {
    display: none;
    position: relative;
    font-family: "Roboto Condensed", sans-serif, helvetica;
    font-weight: bold;
    color: #000;
    border: 0px solid #ccc;
    width: 100%
}

.select--mobile {
    display: inline-block;
    position: relative;
    font: normal 11px/22px Arial, Sans-Serif;
    color: #000;
    border: 0px solid #ccc;
    width: 100%
}

.options.subnavDropdown {
    display: none
}

@media(max-width: 778px) {

    .styledSelect,
    .styledSelectMobile {
        display: block
    }

    .select {
        display: block
    }

    .options.subnavDropdown {
        display: block;
    }
}

.styledSelectMobile {
    min-width: auto;
    height: 31.5px;
    overflow: visible;
    cursor: pointer;
    margin: 12px auto
}

.distributions-wrapper .styledSelect,
.distributions-wrapper .styledSelectMobile {
    max-width: 200px;
    left: initial
}

.distributions-wrapper .options {
    left: initial
}

.styledSelect:active,
.styledSelectMobile:active,
.styledSelect.active,
.active.styledSelectMobile {
    background-color: #d3d3d3
}

.options {
    font-family: "Roboto Condensed", sans-serif, helvetica;
    font-size: 1.6rem;
    display: none;
    position: absolute;
    display: block;
    width: calc(100% - 30px);
    max-width: 100%;
    top: 40px;
    left: 15px;
    z-index: 999;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    border: 2px solid #ddd;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .2)
}

[rel=init] {
    display: none
}

.options li {
    margin: 0px;
    padding: 8px 10px;
    color: #000;
    font-size: 16px;
    position: relative;
    z-index: 6;
    cursor: pointer
}

.options li:hover {
    background-color: #0a85c7;
    color: #fff
}

.options a {
    display: block;
    color: #000;
    font-family: "Roboto", sans-serif, helvetica;
    font-size: 16px;
    transition: none
}

.options li:hover a {
    color: #fff;
    text-decoration: none
}

.options li:hover.mainoptgroup,
.options li:hover.suboptgroup {
    background-color: #fff;
    color: #000
}

.mainoptgroup:before {
    display: block;
    border-top: 2px solid #eee;
    content: ".";
    color: rgba(0, 0, 0, 0);
    margin: 10px 0 -10px 0
}

.mainoptgroup:nth-child(1):before,
.mainoptgroup:nth-child(2):before {
    content: "";
    border-top: none !important;
    margin: 0 0 0 0
}

.suboptgroup {
    font-family: "Roboto", sans-serif, helvetica;
    font-size: 1.6rem;
    font-weight: bold
}

.tabs {
    background: #fff;
    margin-bottom: 15px;
    margin-top: 15px
}

.tabs__list {
    display: block;
    width: 100%;
    font-size: 0;
    border-bottom: 2px #d6dadc solid
}

.tabs__list-item {
    display: inline-block;
    background: none;
    padding: 0
}

.tabs__tab {
    width: 100%;
    min-width: 150px;
    display: block;
    box-sizing: border-box;
    border-bottom: 2px #d6dadc solid;
    background-color: #fff;
    color: #000;
    font-family: "Roboto Condensed", sans-serif, helvetica;
    font-weight: bold;
    font-size: 1.8rem;
    text-transform: uppercase;
    float: left;
    outline: none;
    cursor: pointer;
    padding: 18px 15px;
    margin-bottom: -2px;
    transition: .3s;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.tabs__list-item:last-child .tabs__tab {
    border-right: 0
}

.tabs__tab:hover {
    color: #000;
    text-decoration: none
}

.tabs__tab.active {
    background-color: #f5f5f5;
    color: #000;
    text-transform: uppercase;
    border-bottom: 2px #0a85c7 solid
}

.tabs__tab-content {
    display: none;
    padding: 0;
    border-top: none;
    box-sizing: border-box;
    -webkit-animation: fadeEffect 1s;
    animation: fadeEffect 1s
}

.tabs__tab-content a {
    color: #000
}

.tabs__tab-content a:hover {
    color: #0a85c7
}

@-webkit-keyframes fadeEffect {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadeEffect {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@media(max-width: 778px) {
    .tabs__tab {
        font-size: .9em;
        line-height: .9rem;
        padding: 22px 12px 20px 12px;
        min-width: 0px
    }
}

@media(max-width: 398px) {
    .tabs__tab {
        font-size: .7em;
        line-height: .7rem;
        padding: 22px 6px 20px 6px
    }
}

#tinyNav {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block;
    position: fixed;
    z-index: 666;
    list-style: none;
    bottom: 15px;
    right: 15px;
    padding: 5px;
    height: auto;
    width: auto
}

#tinyNav li {
    height: 30px;
    width: 30px;
    display: block !important;
    content: " ";
    margin-bottom: 5px;
    cursor: pointer
}

#tinyNav li img {
    height: 30px;
    width: 30px
}

#tinyNav_backtotop {
    transform: rotate(-90deg)
}

.no-marg-bottom {
    margin-bottom: 0
}

#tinyNav li:last-child {
    margin-bottom: 0
}

#tinyNav li:hover {
    background-image: none
}

#tinyNav .sticky {
    background-image: none
}

#tinyNav li img {
    height: 18px;
    width: 18px;
    padding: 6px;
    display: block
}

#tinyNav li.alt {
    background-image: none;
    background-color: #0a85c7;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

#tinyTitle {
    display: none;
    position: fixed;
    font-size: 1em;
    font-family: "Roboto", sans-serif, helvetica;
    font-weight: bold;
    line-height: 1em;
    color: #fff;
    top: 0;
    right: 70px;
    float: right;
    padding: 10px 10px 10px 10px;
    z-index: 667;
    background-color: #0a85c7;
    transition: top .3s ease
}

.reportnavbg {
    background: #555;
    height: 40px;
    width: 100%;
    margin-top: 115px;
}

.reportnav>ul {
    width: 100%;
    height: 40px;
    margin: 0 30px;
    display: flex;
    align-items: center;
}

.reportnav>ul>li {
    position: relative;
    float: left;
    list-style: none;
    filter: inherit;
    display: block;
    width: auto;
    margin: 0;
    height: auto;
    padding: 0 20px;
}

.reportnav>ul>li .title {
    color: #fff;
}

.reportnav>ul>li .title.active {
    border-bottom: 1px solid #fff;
}

#team-bios-page img {
    width: 100%;
    max-width: 210px;
}

@media(max-width: 700px) {
    .reportnav>ul {
        margin: 0 auto;
        justify-content: center;
    }
}

/* @media(max-width: 1340px) {
    #tinyNav {
        display: none !important
    }
}

@media(max-height: 480px) {
    #tinyNav {
        display: none !important
    }
}

#tinyNav_mobile {
    display: block;
    position: fixed !important;
    z-index: 666;
    list-style: none;
    bottom: 20px;
    right: 15px;
    background-color: #fff;
    height: auto;
    width: auto;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .3);
    padding: 4px
}

#tinyNav_mobile li {
    background-color: #000;
    height: 30px;
    width: 30px;
    display: block !important;
    content: " ";
    margin-bottom: 5px;
    cursor: pointer;
    padding: 5px
}

#tinyNav_mobile li:last-child {
    margin-bottom: 0
}

#tinyNav_mobile li:hover {
    background-color: #0a85c7;
    background-image: none
}

#tinyNav_mobile .sticky {
    background-color: #0a85c7;
    background-image: none
}

#tinyNav_mobile li img {
    width: 18px;
    height: 18px;
    padding: 20%;
    display: block
}

#tinyNav_mobile li.alt {
    background-image: none;
    background-color: #0a85c7
} */