.overlay--box.contact-us {
    height: 650px;
    width: 900px;
    padding: 0px;
}

.overlay--box.contact-us .overlay--container {
}

.overlay--box.contact-us .overlay--container .container-wrapper {
    height: 615px;
    overflow: auto;
    padding: 20px;
    padding-bottom: 10px;
}

.overlay--box.contact-us .overlay-close {
    border: 1px solid rgb(238, 238, 238);
    background-color: #fff;
    top: 0px;
    right: 0px;
    margin: 20px;
    z-index: 9999;
    cursor: pointer;
    padding: 8px 10px;
}

.container-wrapper.contact-us {}

.container-wrapper.contact-us .tabs {
    margin-top: 0;
}
.container-wrapper.contact-us a {
    color: #000;
}
.clough-contact .checkbox {
    margin-bottom: 5px;
}
.clough-contact .checkbox input{
    margin-right: 5px;
    margin-bottom: 0;
}
.pulsate {
    animation: PULSE 1.25s infinite;
}
@-webkit-keyframes PULSE{
    0%{opacity: 0.3;}	
    110%{opacity: 1;}
 }
 
 @-ms-keyframes PULSE{
    0%{opacity: 0.3;}	
    110%{opacity: 1;}
 }
 
 @keyframes PULSE{
    0%{opacity: 0.3;}	
    110%{opacity: 1;}
 }
@media(max-width: 900px) {
    .overlay--box.contact-us {
        width: 75vw;
    }
}
@media(max-width: 778px) {
    .overlay--box.contact-us {
        height: 90vh;
        width: 75vw;
    }
}