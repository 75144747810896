.overlay--box.about-us {
    height: 600px;
    width: 900px;
    padding: 0px;
}

.overlay--box.about-us .overlay--container {
}

.overlay--box.about-us .overlay--container .container-wrapper {
    height: 560px;
    overflow: auto;
    padding: 30px;
    padding-bottom: 10px;
}

.overlay--box.about-us .overlay-close {
    border: 1px solid rgb(238, 238, 238);
    background-color: #fff;
    top: 0px;
    right: 0px;
    margin: 20px;
    z-index: 9999;
    cursor: pointer;
    padding: 8px 10px;
}

.about-us-wrapper {
    width: 100%;
}

.about-us-wrapper .modal--bios__img {
    border: 0px;
    width: 150px;
}

.about-us-wrapper h4 {
    color: #0a85c7;
    font-size: 2.4rem;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif, helvetica;
    font-style: italic;
    margin: 20px 0 10px 0;
}

.about-us-wrapper h4,
.about-us-wrapper h5 {
    text-align: left;
}
.about-us-wrapper h5 {
    font-weight: 500;
    text-transform: none;
}

.about-us-wrapper .body-content {
    text-align: left;
}

.overlay--box.about-us .overlay--container .swiper-button-next, .overlay--box.about-us .overlay--container .swiper-button-prev {
    top: 50%;
    left: 0;
    position: fixed;
    right: 0;
    width: 900px;
    margin: auto;

}
.overlay--box.about-us .overlay--container .swiper-button-next::after, .overlay--box.about-us .overlay--container .swiper-button-prev::after  {
    font-size: 30px;
    width: 50px;
    height: 100px;
    background: rgb(10, 133, 199);
    color: #fff;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}
.overlay--box.about-us .overlay--container .swiper-button-next::after {
    right: -50px;
    border-radius: 0rem 8rem 8rem 0;
} 
.overlay--box.about-us .overlay--container .swiper-button-prev::after  {
    left: -50px;
    border-radius: 8rem 0rem 0rem 8rem;
}
.overlay--box.about-us .swiper-slide {
    opacity: 0 !important;
    /* display: none; */
} 
.overlay--box.about-us .swiper-slide.swiper-slide-active{
    opacity: 1 !important;
    /* display: block; */
}

@media(max-width: 900px) {
    .overlay--box.about-us {
        height: 90vh;
        width: 75vw;
    }
    .overlay--box.about-us .overlay--container .container-wrapper {
        height: 84vh;
        padding: 20px;
    }
    .overlay--box.about-us .overlay--container .swiper-button-next, .overlay--box.about-us .overlay--container .swiper-button-prev {
        width: 75vw;

    }
}