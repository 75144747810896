.callout {
    border-top: 1px #fff solid;
    box-sizing: border-box;
    padding: 20px 10px;
    min-height: 60px;
    width: 100%;
    background: #0a85c7;
    vertical-align: middle
}

.callout a {
    font-size: 1.8rem;
    color: #fff;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif, helvetica;
    font-weight: bold
}

.tableOverflowIndicator {
    /* color: #000; */
    width: 100%;
    text-align: center;
    margin: 10px 0 30px 0;
    font-family: Arial, sans-serif;
    display: none;
    /* display: block; */
    color: rgb(164, 173, 177);
    animation: change 2s step-end both;
}
@media(max-width: 600px) {
    .tableOverflowIndicator {
        display: block;
    }
}
@keyframes change {
    0% {
        color: orange
    }

    30% {
        opacity: 0;
        transition: 0.3s ease-in-out;
    }

    40% {
        opacity: 0.4;
        color: rgb(164, 173, 177);
        transition: 0.3s ease-in-out;
    }

    50% {
        color: rgb(164, 173, 177);
        opacity: 1;        
    }
}


.tableHorizontalScroll {
    overflow: auto !important;
    width: 100%
}

@media(max-width: 576px) {
    .callout {
        padding: 10px 10px;
        min-height: 30px
    }

    .callout a {
        font-size: 1.2rem
    }
}

@media(max-width: 398px) {
    .callout {
        padding: 5px 10px;
        min-height: 30px
    }

    .callout a {
        font-size: .8rem
    }
}