.bioSection h3 {
    margin-top: 50px
}

.bioSection h4 {
    margin-top: 5px;
    color: #0a85c7;
    font-familiy: "Roboto", sans-serif, helvetica !important;
    font-style: italic
}

.bioSection h4 sup {
    font-size: 1.3rem;
    color: #0a85c7
}

.bioSection h5 {
    text-transform: uppercase;
    margin: 5px 0 20px 0;
    color: #000;
    font-familiy: "Roboto", sans-serif, helvetica !important;
    font-style: italic;
    font-weight: bold;
    font-size: 1.2em;
    line-height: 2rem
}

.bioSection a.bio {
    margin-top: 5px;
    margin-bottom: 30px;
    display: block;
    padding: 10px 0;
    color: #666
}

.bioSection a.bio:hover {
    color: #00b37d;
    text-decoration: none
}

.bioSection .col {
    margin-bottom: 40px
}

.bioSection .span_1_of_3 {
    width: 30%;
    margin-left: 5%
}

.bioSection .span_1_of_3:first-child {
    margin-left: 0px
}

.bioSection img {
    border: 0;
    width: 100%
}

.bioSection img {
    width: 150px;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 20px;
    float: left
}

#chart_inv_process {
    border: 0px solid red;
    display: block;
    width: auto;
    height: 700px;
    text-align: center;
    overflow: hidden;
    position: relative
}

#chart_inv_process .stage {
    border: 0px solid blue;
    height: 90%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 50%;
    width: auto;
    overflow: visible;
    transform: translateX(-50%)
}

#chart_inv_process img.arrows {
    width: 100%;
    height: 100%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    overflow: hidden
}

img.rotate-animation {
    animation: rotate 20s linear infinite;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

#chart_inv_process img.spokes {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    overflow-y: hidden;
    overflow-x: visible
}

#chart_inv_process .stage b {
    font-family: "Roboto", sans-serif, helvetica;
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 15px;
    display: block
}

.circle_blue {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    padding: 30%;
    color: #fff;
    font-size: 1em;
    line-height: 2rem;
    font-family: "Roboto", sans-serif, helvetica;
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.circle_blue::before {
    content: "";
    background: #0a85c7;
    opacity: 1;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    color: #fff;
    z-index: -1;
    border: 0px solid red;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.circle_roll {
    position: absolute;
    height: auto;
    transform: translate(-50%, -50%);
    top: 24%;
    left: 26%;
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out
}

.circle_roll content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 180px;
    height: auto;
    color: #000;
    font-size: 1em;
    line-height: 1.6rem;
    font-family: "Roboto", sans-serif, helvetica;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none
}

.circle_roll.roll_1 {
    top: 20%;
    left: 29%;
    z-index: 11
}


.circle_roll.roll_2 {
    top: 20%;
    left: 71%;
    z-index: 12
}

.circle_roll.roll_3 {
    top: 60%;
    left: 85%;
    z-index: 13
}

.circle_roll.roll_5 {
    top: 60%;
    left: 15%;
    z-index: 15
}

.circle_roll.roll_4 {
    top: 86%;
    left: 50%;
    z-index: 14
}

.circle_roll bg {
    content: "";
    background: #fff;
    opacity: 1;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    color: #fff;
    z-index: -1;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: background .2s ease-in-out
}

.circle_roll bg.roll_1_bg {
    border: 2px solid #d7102c
}

.circle_roll bg.roll_1_bg:hover {
    background: #fae7ea
}

.circle_roll bg.roll_2_bg {
    border: 2px solid #4c656b
}

.circle_roll bg.roll_2_bg:hover {
    background: #eef2f2
}

.circle_roll bg.roll_3_bg {
    border: 2px solid #f8653c
}

.circle_roll bg.roll_3_bg:hover {
    background: #fdf1ee
}

.circle_roll bg.roll_4_bg {
    border: 2px solid #1db2e8
}

.circle_roll bg.roll_4_bg:hover {
    background: #e9f7fc
}

.circle_roll bg.roll_5_bg {
    border: 2px solid #51af54
}

.circle_roll bg.roll_5_bg:hover {
    background: #eff7ef
}

@media(max-width: 1040px) {
    .bioSection .span_1_of_2 {
        width: 100%;
        margin-left: 0 !important;
    }
}
@media(max-width: 778px) {
    .bioSection .span_1_of_2 {
        margin-left: 0 !important;
    }
    .bioSection .span_1_of_3 {
        width: 100%;
        margin-left: 0
    }

    .bioSection img {
        width: 100px !important
    }

    .bios--content table td p {
        font-size: 1.4rem
    }

    .bios--content table td h4 {
        margin: 0px 0px 0px 0px;
        font-size: 2.6rem
    }

    .bios--content table td h5 {
        margin: 10px 0px 0px 0px;
        font-size: 1.8rem
    }

    .bioSection .span_1_of_3 {
        padding: 0 10px;
        width: 32.5% !important
    }

    .bar_chart_legend {
        margin-bottom: 50px;
        padding-left: 20px
    }

    #chart_inv_process .stage {
        transform: translateX(-50%) scale(1)
    }

    #chart_inv_process {
        height: 1300px;
        overflow: hidden
    }

    #chart_inv_process .stage {
        height: 100%
    }

    #chart_inv_process img.arrows {
        transform: scale(0.7)
    }

    #chart_inv_process img.spokes {
        opacity: 0
    }
    .circle_roll.roll_1 {
        top: 12%;
        left: 50%
    }
    .circle_roll.roll_2 {
        top: 31%;
        left: 50%
    }
    .circle_roll.roll_3 {
        top: 50%;
        left: 50%
    }
    .circle_roll.roll_5 {
        top: 88%;
        left: 50%
    }
    .circle_roll.roll_4 {
        top: 69%;
        left: 50%
    }
}
.clough-chart-wrapper .recharts-cartesian-axis-line {
    stroke: rgb(204, 204, 204);

}
.clough-chart-wrapper .recharts-cartesian-axis-tick-line {
    stroke: rgb(204, 204, 204);
}
@media(max-width: 576px) {
    .bioSection .span_1_of_3 {
        width: 100% !important
    }

    .bioSection .span_1_of_3:first-child {
        padding-top: 20px
    }

    .bioSection .col {
        margin-bottom: 20px
    }

    .bioSection img {
        margin-bottom: 60px
    }

    .bioSection:nth-child(1) .col:nth-child(1) img {
        margin-bottom: 120px
    }
}
