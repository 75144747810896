
.table,
.table--reduced-padding,
.table--mod-2,
.table--mod-3,
.table--mod-1 {
    max-width: 1220px;
    width: 100%;
    margin: 10px 0 15px 0;
    line-height: 1.2em;
    box-sizing: border-box;
    z-index: 4;
    position: relative
}

.table th,
.table--reduced-padding th,
.table--mod-2 th,
.table--mod-3 th,
.table--mod-1 th {
    text-align: left;
    padding: 15px;
    color: #000;
    border-top: 1px solid #d6dadc;
    font-size: 1.4rem
}

.table th:first-child,
.table--reduced-padding th:first-child,
.table--mod-2 th:first-child,
.table--mod-3 th:first-child,
.table--mod-1 th:first-child {
    text-align: left
}

.table td,
.table--reduced-padding td,
.table--mod-2 td,
.table--mod-3 td,
.table--mod-1 td {
    padding: 5px 15px;
    vertical-align: middle;
    color: #000;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
    border-top: 1px solid #f5f5f5
}

.table td date,
.table--reduced-padding td date,
.table--mod-2 td date,
.table--mod-3 td date,
.table--mod-1 td date {
    font-size: 1.4rem;
    margin: 0
}

.table td:first-child,
.table--reduced-padding td:first-child,
.table--mod-2 td:first-child,
.table--mod-3 td:first-child,
.table--mod-1 td:first-child {
    text-align: left
}

.table sup,
.table--reduced-padding sup,
.table--mod-2 sup,
.table--mod-3 sup,
.table--mod-1 sup {
    color: #000;
    font-size: .9rem
}

.table--mod-2 {
    margin: 0
}

.table--mod-2 th {
    text-align: center;
    border-top: 0px solid #d6dadc;
    border-bottom: 1px solid #d6dadc
}

.table--mod-2 td {
    border: 0px;
    padding: 18px 12px 16px 12px;
    text-align: left
}

.table--mod-2 td:first-child {
    margin-right: 2px;
    border: 0px solid red;
    border-bottom: 2px solid #ddd;
    width: 50%
}

.table--mod-2 td:last-child {
    margin-left: 2px;
    border: 0px solid red;
    border-bottom: 2px solid #ddd;
    width: 50%;
    position: relative
}

.table--mod-2 td>span {
    float: right;
    margin-left: 10px
}

.table--mod-2 td.noborder {
    border: 0px
}

.table--mod-2 td div.customBlock {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin: 23px 0px 0px 12px;
    padding-right: 60px;
    border: 0px solid red
}

.table--mod-2 td div.customBlock span {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    z-index: 1;
    text-align: right;
    border: 0px solid blue
}
.table--mod-3 {
    margin: 0;
    margin-bottom: 15px
}

.table--mod-3 th {
    text-align: center;
    border-top: 0px solid #d6dadc;
    border-bottom: 2px solid #d6dadc;
    white-space: nowrap
}

.table--mod-3 td {
    border: 0px;
    padding: 18px 12px 16px 12px;
    text-align: center;
    border-bottom: 2px solid #ddd
}

.table--mod-3 td:first-child {
    text-align: left;
    min-width: 100px
}

.table--mod-3 b {
    font-size: 1em
}

.table--mod-3.holdings.categories {
    margin-top: 50px
}

.table--mod-3.holdings.categories tr:nth-child(2) th {
    width: 30%
}

.table--mod-3.holdings.categories tr:nth-child(2) th:first-child {
    width: 60%
}

.table--mod-3.holdings.categories tr:nth-child(2) th:last-child {
    width: 10%
}

.table--mod-3.holdings.categories tr:first-child th.header {
    background: #f5f5f5
}

.table--mod-3.holdings.categories tr:first-child th.header h3 {
    margin-top: 0px
}
@media(max-width: 778px) {
    .table--mod-2 div.customBlock {
        font-size: .8em
    }
}

@media(max-width: 576px) {
    .table--mod-2 div.customBlock {
        padding-top: 20px
    }
}