html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

body {
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased
}

div,
object,
iframe {
  margin: 0;
  padding: 0;
  border: 0
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline
}

audio:not([controls]) {
  display: none;
  height: 0
}

[hidden],
template {
  display: none
}

a {
  background-color: rgba(0, 0, 0, 0)
}

body:not(.user-is-tabbing) a:active,
body:not(.user-is-tabbing) a:hover {
  outline: 0
}

abbr[title] {
  border-bottom: 1px dotted
}

b,
strong {
  font-weight: bold
}

dfn {
  font-style: italic
}

h1,
h2,
h3,
h4,
p,
blockquote,
figure,
ol,
ul,
li {
  margin: 0;
  padding: 0
}

main,
li {
  display: block
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit
}

strong {
  font-weight: bold
}

a,
button {
  color: inherit
}

a {
  text-decoration: none
}

button {
  overflow: visible;
  border: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  background: none;
  cursor: pointer
}

body:not(.user-is-tabbing) ::-moz-focus-inner {
  padding: 0;
  border: 0
}

body:not(.user-is-tabbing) :focus {
  outline: 0
}

mark {
  background: #ff0;
  color: #000
}

small {
  font-size: 80%
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sup {
  top: -0.5em
}

sub {
  bottom: -0.25em
}

img {
  border: 0
}

svg:not(:root) {
  overflow: hidden
}

figure {
  margin: 1em 40px
}

hr {
  box-sizing: content-box;
  height: 0
}

pre {
  overflow: auto
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0
}

button {
  overflow: visible
}

button,
select {
  text-transform: none
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: none;
  cursor: pointer
}

button[disabled],
html input[disabled] {
  cursor: default
}

body:not(.user-is-tabbing) button::-moz-focus-inner,
body:not(.user-is-tabbing) input::-moz-focus-inner {
  border: 0;
  padding: 0
}

input {
  line-height: normal
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto
}

input[type=search] {
  -webkit-appearance: textfield;
  box-sizing: content-box
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

fieldset {
  border: 0px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em
}

legend {
  border: 0;
  padding: 0
}

textarea {
  overflow: auto
}

optgroup {
  font-weight: bold
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

td,
th {
  padding: 0
}

waypoint,
.waypoint {
  display: block;
  opacity: .001;
  height: 0
}

* {
  outline-color: #0a85c7;
  -webkit-tap-highlight-color: #0a85c7
}

html {
  font-size: 62.5%;
  overflow-x: hidden
}

body {
  font-size: 1.4rem;
  font-weight: normal;
  font-family: "Roboto Condensed", sans-serif, helvetica;
  background: #fff;
  color: #000;
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: auto;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

body.modal-open,
body.mobilenav-open {
  overflow: hidden;
  position: fixed
}

@media(max-width: 992px) {

  body.modal-open,
  body.mobilenav-open {
    position: fixed
  }
}

header {
  text-align: left;
  margin: 0px auto;
  padding: 0px;
  display: block;
  position: relative;
  border: 0px solid blue;
  width: 100%;
  height: auto
}

.spacer {
  display: block;
  height: 60px;
  width: 100%
}

date {
  color: #000;
  margin: 5px 0;
  display: inline-block
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  height: auto;
  overflow: visible;
  display: block
}

h1 {
  font-family: "Roboto", sans-serif, helvetica;
  font-style: italic;
  font-weight: 300;
  font-size: 7.9rem;
  margin: 30px 0px 5px 0px;
  padding: 0px;
  line-height: 1.1em;
  text-transform: uppercase
}

h2 {
  font-family: "Roboto", sans-serif, helvetica;
  font-style: italic;
  font-weight: 300;
  font-size: 4rem;
  margin: 30px 0px 5px 0px;
  padding: 0px;
  line-height: 1.1em;
  text-transform: uppercase
}

h3 {
  font-family: "Roboto", sans-serif, helvetica;
  font-style: italic;
  font-weight: 300;
  font-size: 3rem;
  font-weight: 300;
  margin: 40px 0px 0px 0px;
  padding: 0;
  letter-spacing: 0;
  line-height: 1.4em;
  text-transform: uppercase
}

h3.underline {
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 10px
}

h3.reducedmargin {
  margin-top: 20px
}

h3.mod1 {
  font-family: "Roboto", sans-serif, helvetica;
  font-size: 2.2rem;
  font-weight: bold;
  font-style: italic;
  display: inline-block
}

h3.mod1 span {
  text-transform: none;
  font-family: "Roboto", sans-serif, helvetica;
  font-style: normal
}

h3.mod2 {
  font-family: "Roboto", sans-serif, helvetica;
  font-size: 2.2rem;
  font-style: italic;
  font-weight: bold;
  margin: 20px 0px 0px 0px;
  display: block
}

h3.mod2.underline {
  margin-bottom: 0
}

h4 {
  font-family: "Roboto", sans-serif, helvetica;
  font-size: 2.2rem;
  font-weight: normal;
  font-style: normal;
  color: #000;
  margin: 50px 0px 5px 0px;
  letter-spacing: 0;
  padding: 0px;
  line-height: 1.1em;
  text-transform: uppercase
}

h5 {
  font-family: "Roboto", sans-serif, helvetica;
  font-size: 2rem;
  font-weight: normal;
  margin: 20px 0px 5px 0px;
  padding: 0px;
  line-height: 1.1em;
  text-transform: uppercase
}

h6 {
  font-size: 1.8rem;
  margin: 20px 0px 5px 0px;
  padding: 0px;
  line-height: 1.2em;
  font-weight: normal;
  text-transform: uppercase
}

p {
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.5888rem;
  color: #000;
  margin-bottom: 15px
}

b {
  font-family: "Roboto", sans-serif, helvetica;
  font-size: 1.6rem;
  line-height: 1.5rem;
  color: #000;
  margin-bottom: 20px
}

b.mod1 {
  font-family: "Roboto", sans-serif, helvetica;
  font-weight: bold;
  margin-top: 35px;
  margin-bottom: 10px;
  display: block;
  font-weight: normal
}

sup {
  vertical-align: baseline;
  font-size: 1rem;
  color: #000;
  font-weight: normal
}

img {
  max-width: 100%
}

.disclosure {
  font-style: normal;
  font-size: 1.4rem;
  line-height: 2.5888rem;
  color: #000;
  font-weight: normal;
  font-style: italic
}

.disclosure sup {
  font-size: 1rem;
  color: #000
}

.disclosure a {
  color: color-secondary-4;
  font-weight: bold;
  text-decoration: underline
}

.disclosure a:hover {
  color: #51626f
}

.disclosure b {
  color: #000;
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif, helvetica;
  font-weight: bold;
  line-height: 140%;
  display: inline-block;
  margin-bottom: 0
}

.disclosure b.header {
  color: #000;
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif, helvetica;
  font-weight: bold
}

.disclosure .disclosure_link_mod1 {
  color: #000;
  text-decoration: none;
  font-weight: normal
}

.disclosure .disclosure_link_mod2 {
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 120%
}

p.disclosure {
  margin-bottom: 10px;
  line-height: 120%
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #0a85c7;
  font-size: 1.6rem;
  z-index: 5
}

a:hover {
  text-decoration: underline
}

.main_content {
  min-height: 400px
}

#mainContent .inner-content {
  padding-top: 289px
}

#mainContent .inner-content--callout {
  padding-top: 350px
}

#mainContent .inner-content--callout.video-element,
#mainContent .inner-content.video-element {
  padding-top: 0px;
}

.row {
  clear: both;
  display: block;
  overflow: auto
}

.container {
  position: relative;
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
  clear: both;
  display: block
}

@media(min-width: 576px) {
  .container {
    width: 100%
  }
}

@media(min-width: 778px) {

  .column,
  .columns,
  .col {
    margin-left: 2%;
    min-height: 1px
  }

  .column:first-child,
  .columns:first-child,
  .col:first-child {
    margin-left: 0
  }
}

.col {
  display: block;
  float: left;
  margin: 0 0 1% 3%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box
}

.col:first-child {
  margin-left: 0
}

.span_2_of_2 {
  width: 100%
}

.span_1_of_2 {
  width: 48.5%
}

.span_3_of_3 {
  width: 100%
}

.span_2_of_3 {
  width: 65.6601%
}

.span_1_of_3 {
  width: 31.3302%
}

.span_4_of_4 {
  width: 100%
}

.span_3_of_4 {
  width: 74.25%
}

.span_2_of_4 {
  width: 48.5%
}

.span_1_of_4 {
  width: 22.75%
}

.span_5_of_5 {
  width: 100%
}

.span_4_of_5 {
  width: 79.4%
}

.span_3_of_5 {
  width: 58.8%
}

.span_2_of_5 {
  width: 38.2%
}

.span_1_of_5 {
  width: 17.6%
}

.span_6_of_6 {
  width: 100%
}

.span_5_of_6 {
  width: 82.8302%
}

.span_4_of_6 {
  width: 65.6604%
}

.span_3_of_6 {
  width: 48.5006%
}

.span_2_of_6 {
  width: 31.3308%
}

.span_1_of_6 {
  width: 14.161%
}

.span_7_of_7 {
  width: 100%
}

.span_6_of_7 {
  width: 85.2816%
}

.span_5_of_7 {
  width: 70.5632%
}

.span_4_of_7 {
  width: 55.8548%
}

.span_3_of_7 {
  width: 41.1364%
}

.span_2_of_7 {
  width: 26.428%
}

.span_1_of_7 {
  width: 11.7096%
}

.span_8_of_8 {
  width: 100%
}

.span_7_of_8 {
  width: 87.125%
}

.span_6_of_8 {
  width: 74.25%
}

.span_5_of_8 {
  width: 61.375%
}

.span_4_of_8 {
  width: 48.5%
}

.span_3_of_8 {
  width: 35.625%
}

.span_2_of_8 {
  width: 22.75%
}

.span_1_of_8 {
  width: 9.875%
}

.span_9_of_9 {
  width: 100%
}

.span_8_of_9 {
  width: 88.5467%
}

.span_7_of_9 {
  width: 77.1034%
}

.span_6_of_9 {
  width: 65.6601%
}

.span_5_of_9 {
  width: 54.2168%
}

.span_4_of_9 {
  width: 42.7735%
}

.span_3_of_9 {
  width: 31.3302%
}

.span_2_of_9 {
  width: 19.8869%
}

.span_1_of_9 {
  width: 8.4436%
}

.span_10_of_10 {
  width: 100%
}

.span_9_of_10 {
  width: 89.7%
}

.span_8_of_10 {
  width: 79.4%
}

.span_7_of_10 {
  width: 69.1%
}

.span_6_of_10 {
  width: 58.8%
}

.span_5_of_10 {
  width: 48.5%
}

.span_4_of_10 {
  width: 38.2%
}

.span_3_of_10 {
  width: 27.9%
}

.span_2_of_10 {
  width: 17.6%
}

.span_1_of_10 {
  width: 7.3%
}

.span_11_of_11 {
  width: 100%
}

.span_10_of_11 {
  width: 90.6273%
}

.span_9_of_11 {
  width: 81.2646%
}

.span_8_of_11 {
  width: 71.9019%
}

.span_7_of_11 {
  width: 62.5392%
}

.span_6_of_11 {
  width: 53.1765%
}

.span_5_of_11 {
  width: 43.8138%
}

.span_4_of_11 {
  width: 34.4511%
}

.span_3_of_11 {
  width: 25.0884%
}

.span_2_of_11 {
  width: 15.7257%
}

.span_1_of_11 {
  width: 6.363%
}

.span_12_of_12 {
  width: 100%
}

.span_11_of_12 {
  width: 91.4101%
}

.span_10_of_12 {
  width: 82.8302%
}

.span_9_of_12 {
  width: 74.2503%
}

.span_8_of_12 {
  width: 65.6604%
}

.span_7_of_12 {
  width: 57.0805%
}

.span_6_of_12 {
  width: 48.5006%
}

.span_5_of_12 {
  width: 39.9107%
}

.span_4_of_12 {
  width: 31.3308%
}

.span_3_of_12 {
  width: 22.7509%
}

.span_2_of_12 {
  width: 14.161%
}

.span_1_of_12 {
  width: 5.5811%
}

@media(max-width: 1200px) {
  .lg-full {
    width: 100%;
    margin: 1% 0 1% 0%
  }
}

@media(max-width: 778px) {

  .span_1_of_2,
  .span_2_of_2,
  .span_1_of_3,
  .span_2_of_3,
  .span_3_of_3,
  .span_1_of_4,
  .span_2_of_4,
  .span_3_of_4,
  .span_4_of_4,
  .span_1_of_5,
  .span_2_of_5,
  .span_3_of_5,
  .span_4_of_5,
  .span_5_of_5,
  .span_1_of_6,
  .span_2_of_6,
  .span_3_of_6,
  .span_4_of_6,
  .span_5_of_6,
  .span_6_of_6,
  .span_1_of_7,
  .span_2_of_7,
  .span_3_of_7,
  .span_4_of_7,
  .span_5_of_7,
  .span_6_of_7,
  .span_7_of_7,
  .span_1_of_8,
  .span_2_of_8,
  .span_3_of_8,
  .span_4_of_8,
  .span_5_of_8,
  .span_6_of_8,
  .span_7_of_8,
  .span_8_of_8,
  .span_1_of_9,
  .span_2_of_9,
  .span_3_of_9,
  .span_4_of_9,
  .span_5_of_9,
  .span_6_of_9,
  .span_7_of_9,
  .span_8_of_9,
  .span_9_of_9,
  .span_1_of_10,
  .span_2_of_10,
  .span_3_of_10,
  .span_4_of_10,
  .span_5_of_10,
  .span_6_of_10,
  .span_7_of_10,
  .span_8_of_10,
  .span_9_of_10,
  .span_10_of_10,
  .span_1_of_11,
  .span_2_of_11,
  .span_3_of_11,
  .span_4_of_11,
  .span_5_of_11,
  .span_6_of_11,
  .span_7_of_11,
  .span_8_of_11,
  .span_9_of_11,
  .span_10_of_11,
  .span_11_of_11,
  .span_1_of_12,
  .span_2_of_12,
  .span_3_of_12,
  .span_4_of_12,
  .span_5_of_12,
  .span_6_of_12,
  .span_7_of_12,
  .span_8_of_12,
  .span_9_of_12,
  .span_10_of_12,
  .span_11_of_12,
  .span_12_of_12 {
    width: 100%
  }

  .col {
    margin: 1% 0 1% 0%
  }
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden
}

.full-width {
  width: 100%;
  box-sizing: border-box
}

.flex-grid {
  display: flex
}

.flex-grid .col {
  flex: 1
}

@media(max-width: 992px) {
  .flex-grid {
    display: block
  }

  .flex-grid .col {
    width: 100%;
    margin: 0 0 10px 0
  }
}

.site-logo {
  margin: 0px;
  position: relative;
  padding: 0px 0 10px 0;
  display: inline-block;
  max-width: 85%;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.meta-nav__wrapper .container {
  padding: 0 20px
}

.meta-nav {
  text-align: right;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.meta-nav__item {
  font-family: "Roboto Condensed", sans-serif, helvetica;
  font-weight: bold !important;
  background: none;
  display: inline-block;
  padding: 0
}

.meta-nav__link {
  margin-left: 40px;
  color: #000;
  font-size: 1.4rem
}

.meta-nav__link:hover {
  text-decoration: none;
  color: #51626f
}

#navamongus {
  z-index: 1010;
  position: fixed;
  background-color: #fff;
  border-bottom: #727477;
  top: 0;
  left: 0;
  border: 0px solid orange;
  height: 114px;
  width: 100%
}

.nav_container {
  position: relative;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box
}

/* #main-nav {
  text-transform: uppercase;
  margin: 0px auto;
  padding: 0px;
  width: auto;
  height: auto;
  display: inline-block;
  opacity: 1;
  position: absolute;
  bottom: 0px;
  right: 15px;
  word-spacing: 0 !important;
  line-height: 0 !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
} */

#main-nav>li {
  float: none;
  display: inline-block;
  width: auto;
  height: auto;
  padding: 0px;
  height: auto;
  background-color: none;
  list-style-type: none;
  background: url("") 0px 0px no-repeat;
  cursor: default
}

#main-nav>li>a {
  font-family: "Roboto Condensed", sans-serif, helvetica;
  font-weight: bold;
  display: inline-block;
  font-size: 1.6rem;
  line-height: 3em;
  cursor: pointer;
  color: #000;
  margin: 8px 20px 0px 20px;
  /* border-bottom: 5px solid #fff; */
  text-decoration: none
}

#main-nav>li:last-child>a {
  margin-right: 5px
}

#main-nav>li>a.active {
  border-bottom: 5px solid #000
}

@media(max-width: 992px) {
  #main-nav li {
    height: auto
  }

  #main-nav li>a {
    display: none !important
  }
}

.main-nav-divider {
  display: inline-block;
  height: 260px;
  border-left: 1px dashed #fff;
  margin: 20px 20px 0px 20px;
  width: 1px;
  background: rgba(0, 0, 0, 0);
  position: relative
}

.main-nav-dropdown__link {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16' viewBox='0 0 10 16'%3E%3Cpath fill-rule='evenodd' d='M5 11L0 6l1.5-1.5L5 8.25 8.5 4.5 10 6l-5 5z'/%3E%3C/svg%3E");
  background-position: center right;
  background-repeat: no-repeat;
  padding-right: 18px;
  background-size: 14px 20px;
  cursor: pointer
}

.main-nav-dropdown {
  z-index: 20;
  width: 100vw;
  left: 0;
  text-align: center;
  height: auto;
  display: none;
  margin: 0 auto;
  padding: 0;
  position: fixed;
  top: 114px
}

.main-nav-dropdown ul {
  position: relative;
  display: inline-block;
  text-align: left;
  margin: 0px auto;
  vertical-align: top;
  padding: 30px 0 0 0;
  list-style-type: none;
  box-sizing: border-box;
  height: auto;
  border: 0px solid red
}

.main-nav-dropdown__bg {
  z-index: -1;
  display: none;
  top: 114px;
  left: 0;
  height: 40px;
  background: rgba(0, 0, 0, .8);
  position: fixed;
  width: 100%;
  height: 300px;
  cursor: default
}

.main-nav-dropdown ul li {
  width: auto;
  margin: 0px auto;
  padding: 0px;
  background: none;
  display: block;
  border: 0px solid red
}

.main-nav-dropdown ul li.header {
  display: block;
  width: auto;
  padding: 12px 8px 22px 8px;
  text-align: left;
  font-family: "Roboto Condensed", sans-serif, helvetica;
  font-weight: bold;
  font-size: 1.1em;
  line-height: 1.1rem;
  color: #fff;
  white-space: nowrap;
  text-transform: none;
  border: 0px solid blue;
  cursor: default
}

.main-nav-dropdown ul li a {
  display: block;
  width: auto;
  padding: 12px 8px;
  text-align: left;
  font-family: "Roboto Condensed", sans-serif, helvetica;
  font-weight: bold;
  font-size: 1.1em;
  line-height: 1.1rem;
  color: #fff;
  white-space: nowrap;
  text-transform: none;
  border: 0px solid blue
}

.main-nav-dropdown ul li a:hover {
  color: #a2b2c8;
  text-decoration: none
}

.main-nav-dropdown ul li a.activeItem {
  background-color: #fff;
  color: #51626f
}

.icon.active div {
  transform: rotate(90deg)
}

@media(min-width: 992px) {
  #mobilemenu {
    display: none
  }
}

@media(max-height: 880px) {
  #mobilemenu {
    overflow: auto
  }
}

.btn,
.button,
.button--secondary,
.button--primary,
button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 0;
  box-sizing: border-box;
  transition: all .15s ease-in-out;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  background-color: #000;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 18px 10px 18px;
  border: 0;
  width: auto;
  min-width: 100px;
  font-family: "Roboto Condensed", sans-serif, helvetica;
  font-weight: bold;
  font-size: 1.4rem;
  color: #fff;
  clear: both
}

.btn:hover,
.button:hover,
.button--secondary:hover,
.button--primary:hover,
button:hover,
.btn:focus,
.button:focus,
.button--secondary:focus,
.button--primary:focus,
button:focus {
  color: #fff;
  background: #4e656b;
  text-decoration: none
}

.button--primary {
  padding: 14px 30px
}

.button--secondary {
  padding: 14px 30px;
  background: #0a85c7
}

.button--secondary:hover,
.button--secondary:focus {
  background: #086a9f
}

ul {
  padding-left: 0;
  list-style-type: disc;
  list-style-position: outside
}

ul li {
  color: #000;
  font-weight: normal;
  font-size: 1.6rem;
  list-style-type: none;
  background-size: 10px 10px;
  padding-left: 0;
  padding-bottom: 10px;
  margin: auto 0;
  line-height: 1.3em;
  display: list-item;
  z-index: 5
}

ul li ul {
  padding-left: 15px;
  padding-top: 15px
}

ul ul li {
  list-style: none
}

footer {
  display: block;
  margin: 0 auto;
  margin-top: 0px;
  margin-bottom: 80px;
  position: relative;
  color: #fff;
  clear: both;
  z-index: 2
}

.footer__call-us {
  padding-top: 15px;
  font-size: 1.8rem;
  font-family: "Roboto", sans-serif, helvetica;
  font-style: italic;
  font-weight: 300;
  text-align: left;
  white-space: normal;
  text-align: right
}

.footer__call-us a {
  font-size: 1.8rem;
  margin-left: 0px !important
}

.footer__contact-us {
  padding-top: 10px
}

.footer-nav {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 3px;
  background-color: #0a85c7;
  width: 100%
}

.footer-nav li {
  padding: 0 0 10px 0;
  background: none
}

.footer-nav a,
.footer-nav button {
  color: #fff;
  outline-color: #fff;
  margin: 4px
}

.footer-nav a:hover {
  text-decoration: underline
}

.footer-nav p {
  color: #fff
}

.footer__heading {
  color: #fff
}

.footer_line {
  border-left: 1px solid #fff;
  height: 30px;
  width: 0px;
  margin: 0 20px;
  display: inline-block
}

.footer_container {
  border: 0px solid red;
  width: auto;
  display: inline-block;
  text-align: left
}

.disclosure b,
.disclosure strong {
  font-weight: normal;
  font-family: "Roboto Condensed", sans-serif, helvetica;
  font-weight: bold;
  font-size: 1.6rem
}

#mainFooter .flex-grid {
  width: 300px
}

@media(max-width: 990px) {

  .footer__call-us,
  .footer__contact-us {
    text-align: left
  }

  .button--primary.modal-contact-link {
    margin-bottom: 20px
  }

  .footer_line {
    height: 10px;
    border-left: 0px solid #fff;
    display: block
  }
}
#modalOverlay {
  position: relative;
}
.overlay--bg {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1500;
  height: 100%;
  width: 100%;
  background-color: rgb(114, 116, 119);
  opacity: 0.8;
  margin-top: 0px;
  margin-bottom: 86px;
  box-sizing: border-box
}

.overlay--box {
  position: fixed;
  inset: 0px;
  margin: auto;
  z-index: 9999;
  width: 400px;
  height: 400px;
  padding: 0px;
  border-radius: 0px;
  background-color: rgb(255, 255, 255);
  overflow: visible;
}
.overlay--container {
  position: relative;
}

.overlay-close {
  position: absolute;
  right: 0px;
  top: 0px;
}

/* modal,
modalcontactwrapper {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2008;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: none;
  opacity: 1;
  margin-top: 0px;
  margin-bottom: 86px;
  box-sizing: border-box
}

modal--close {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  right: 0;
  margin: 0 0px;
  z-index: 9999
}

modal--close img {
  position: absolute;
  background: rgba(255, 255, 255, .6);
  border: 1px solid #eee;
  top: 0;
  right: 0;
  margin: 20px;
  z-index: 9999;
  cursor: pointer;
  padding: 8px 10px
}

modal--close img:hover {
  background: #fff
}

modal--box,
modal--contact {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  min-height: 400px;
  overflow: visible
}

modal--overflow-scroll {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0px;
  width: 100%;
  height: 100%;
  min-height: 400px;
  overflow-y: scroll;
  -ms-overflow-y: auto
}

modal>div,
modalcontactwrapper>div {
  position: relative;
  height: 100%;
  width: 100%
}

modal>div:before,
modalcontactwrapper>div:before {
  background-color: #727477;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: .9;
  box-sizing: border-box
} */

/* @media(min-width: 1280px) {

  modal--box,
  modal--contact {
    top: 50%;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    transform: translate(0%, -50%)
  }
} */

fieldset {
  margin: 0;
  padding: 0
}

fieldset legend {
  margin-top: 12px;
  margin-bottom: 4px
}

fieldset label {
  display: block
}

input {
  background: #fff;
  border: 1px solid #d6dadc;
  color: #000;
  padding: 8px;
  margin: 0px auto;
  font-size: 1.3rem;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px
}

input:focus {
  background-color: #fff
}

::-webkit-input-placeholder {
  color: #d6dadc
}

:-moz-placeholder {
  color: #d6dadc
}

::-moz-placeholder {
  color: #d6dadc
}

:-ms-input-placeholder {
  color: #d6dadc
}

select:not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border-radius: 0px;
  width: 356px;
  min-width: auto;
  height: 31.5px;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  overflow: visible;
  color: #000;
  font-family: "Roboto Condensed", sans-serif, helvetica;
  font-weight: bold;
  cursor: pointer;
  padding: 8px 12px;
  margin: 12px auto;
  background-color: #fff;
  border: 2px solid #0a85c7;
  display: none
}

select:focus,
option:focus,
select::-moz-focus-inner,
option::-moz-focus-inner {
  color: #f5f5f5
}

select:-moz-focusring {
  color: #d6dadc;
  text-shadow: 255 255 255 #fff;
  border: 1;
  color: rgba(0, 0, 0, 0);
  text-shadow: 0 0 0 #000
}

select:active,
select:hover {
  outline-color: #b1c4c9
}

select::-ms-expand {
  display: none
}

textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #d6dadc;
  background: #fff;
  display: block;
  box-sizing: border-box
}

label {
  font-family: "Roboto Condensed", sans-serif, helvetica;
  font-weight: bold;
  font-size: 1.4rem;
  color: #000;
  display: inline;
  margin-right: 5px
}

input[type=radio],
input[type=radiobutton] {
  margin: 0px auto;
  margin-right: 5px;
  padding: 5px;
  display: inline;
  width: auto
}

input[type=check],
input[type=checkbox] {
  padding: 5px;
  display: inline;
  width: auto
}

#charNum {
  margin: 3px 0 0;
  font-weight: 500;
  font-style: italic;
  font-size: 12px;
  position: absolute;
  right: 40px
}

[for=formContact-element-0] {
  display: none
}

#formContact-element-0 {
  opacity: .01;
  height: 1px;
  content: "";
  font-size: 0;
  line-height: 0;
  padding: 0 !important;
  border: 0
}

.alert-error {
  text-align: left;
  color: red;
  margin-top: 15px;
  margin-bottom: 15px;
  display: none;
  position: relative
}

.alert-error sup {
  font-size: .9rem;
  margin-right: 6px;
  margin-left: 0
}

.alert-error ul {
  margin-top: 10px;
  margin-left: 20px
}

.alert-error li {
  margin-top: 10px;
  color: red;
  padding-bottom: 0;
  background: none;
  padding-left: 0px
}

.alert-error>strong {
  padding-left: 20px;
  display: block
}

.flow {
  text-align: left
}

.flow span {
  color: red
}

.formSub h5 {
  text-align: left;
  color: green;
  font-size: 1.5rem;
  line-height: 1.2em
}

.closeX {
  display: inline-block;
  margin-right: 10px;
  position: absolute;
  left: 4px;
  top: -1px;
  z-index: 1
}

.footerFormWrapper {
  text-align: left;
  padding: 30px
}
.footerFormWrapper_Bottom {
  height: 100%;
}