.hero-slideshow {
  font-family: inherit;
  font-weight: 300;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  margin: 0 auto;
  padding: 0;
  border: 0;
  height: auto;
  width: auto;
  position: relative;
  z-index: 4;
  top: 0px;
  left: 0px;
  height: 365px;
  width: 100%;
  text-align: center;
  display: block;
  opacity: 1
}

.hero-slideshow__content {
  padding: 0;
  width: 100%;
  max-width: 1220px;
  margin: 0px auto;
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 100%;
  height: 365px
}

.hero-slideshow__content .col {
  height: 365px
}

.hero-slideshow__content .hero__title--static {
  text-align: left;
  color: #000;
  font-size: 4rem;
  font-weight: 300;
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: none;
  transition: all 250ms linear
}

.hero_home_bg {
  background: rgba(255, 255, 255, 0);
  position: absolute;
  top: 0px;
  left: 50%;
  width: 100%;
  height: 365px;
  z-index: -1
}

.hero_home_bg:after {
  content: " ";
  position: absolute;
  display: block;
  width: 200%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgba(255, 255, 255, .8);
  transform-origin: top right;
  transform: skew(-40deg, 0deg)
}

.hero_content_wrapper {
  position: relative;
  height: 365px
}

.hero_content_position {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  height: auto;
  transform: translate(-50%, -50%);
  text-align: left
}

.hero-slideshow__content .hero__title--static {
  text-align: left;
  color: #000;
  font-size: 4rem;
  font-weight: 300;
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: none;
  transition: all 250ms linear
}

.slider-video-container {
  position: absolute;
  right: 10px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slider-video-container .thumbnail-container {
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  min-width: 400px;
  height: 300px;
  background-color: #fff;
  box-shadow: 0 4px 2px rgba(0, 0, 0, .2)
}

.slider-video-container .thumbnail-container .thumbnail-flex-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.slider-video-container .thumbnail-container img {
  max-height: 220px;
  cursor: pointer;
  width: 80%; 
  margin: auto;
  margin-top: 15px;
  object-fit: contain;
  position: relative;
}
.slider-video-container .thumbnail-container .thumbnail-header {
  background-color: #0a85c7;
  color: #fff;
  min-height: 40px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-video-container .thumbnail-container .thumbnail-description {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-video-container .thumbnail-container .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 68px;
  height: 48px;
  cursor: pointer;
}

.slider-video-container .thumbnail-container .play-icon svg {
  width: 100%;
  height: 100%;
}

.hero-slideshow__content.mobile_hide {
  margin: 0;
}

@media(max-width: 778px) {
  .hero-slideshow__content .col {
    padding: 0 20px;
    margin: 0;
  }

  .mobile_wrapper {
    position: absolute;
  }

  .hero_home_bg:after {
    background: rgba(255, 255, 255, .85);
    transform: none;
  }

  .hero_home_bg {
    left: 0%;
    width: 200%;
  }

  .hero_content_position {
    width: 70%
  }
}

@media(max-width: 576px) {
  .hero-slideshow__content .hero_content_position {
    width: 100%
  }
  .slider-video-container {
    right: unset;
    width: 100%;
  }
  .slider-video-container .thumbnail-container {
    width: 80%;
    margin: auto;
  }
}

@media(max-width: 398px) {

  .hero-slideshow__content p,
  .hero-slideshow__content .hero__title--static {
    font-size: 2.4rem
  }
}

.swiper {
  width: 100%;
  /* height: 100%; */
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 30px;
}

.swiper-pagination-bullet {
  opacity: 1;
  width: 15px;
  height: 15px;
  min-width: initial;
  background-color: #414042;
  border: 2px solid #fff;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 2px;
  margin: 0;
  margin-right: 12px;
  cursor: pointer;
  border-radius: 0;
  transition: background-color .3s ease;
}

.swiper-pagination-bullet-active {
  background: #727477;
}

.header-slideShow .swiper-button-next,
.header-slideShow .swiper-button-prev {
  display: none;
}